<template>
   <section class="chat-app-window d-flex flex-column" ref="refChatContainer">
    <div class="active-chat">
      <vue-perfect-scrollbar
        v-once
        ref="refChatLogPS"
        :settings="perfectScrollbarSettings"
        class="user-chats scroll-area rounded-lg border-primary"
        :style="{height: chatHeight}"
      >
        <div v-for="(poll, index) of sessionPolls" :key="pollKey+'-'+index">
          <poll :key="pollKey+'-'+poll.id" :question="poll.question" :answers="poll.answers" @addvote="addVote" :showResults="hasVoted(poll.id)" />
          <span v-if="sessionEnded && poll.correct.length>0" class="font-weight-bold">Correct answer: {{poll.correct}}</span>
        </div>
      </vue-perfect-scrollbar>
    </div>
  </section>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Poll from "./Poll.vue";

export default {
  components: {
    VuePerfectScrollbar,
    Poll,
  },
  data() {
    return {
      pollKey: this.$date().valueOf(),
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
    };
  },
  watch: {
    sessionPolls: {
      deep: true,
      immediate: true,
      handler() {
        this.pollKey = this.$date().valueOf()
        // console.log('userPoll:watch:polls', this.pollKey, this.sessionPolls)
      }
    },
    pollKey() {
      this.$forceUpdate()
    }
  },
  computed: {
    chatHeight() {
      // console.log('height', this.$refs)
      // console.log('parent height', this.$parent)
      // console.log('chatHeight:props', this.height)
      let _height = this.height || (this.$parent?.$el?.offsetHeight && this.$parent.$el.offsetHeight) + 'px'
      // console.log('_height', _height)
      return _height
    },
    sessionEnded() {
        return this.$date().isAfter(this.session.scheduledEnd)
    },
    sessionPolls() {
      let _polls = this.$store.getters['sessions/sessionPolls'](this.session.slug)
      // console.log('sessionPolls', _polls)
      // // console.log('this.currentSession', this.currentSession);
      // if (this.session && this.session.polls) {
      //   _polls = this.session.polls
      // }
      return _polls
    },
  },
  methods: {
    hasVoted(pollId) {
      let _voted = localStorage.getItem('hmzep-'+pollId)
      // console.log('hasVoted:pollId', pollId, _voted)
      if (_voted && _voted === "1") {
        // console.log('hasVoted:pollId', 'returning true')
          return true
      } 
      // console.log('hasVoted:pollId', 'returning false')
      return false
    },
    addVote(obj) {
      if (obj.value) {
        // console.log(obj);
        this.$store.dispatch("wsSendPollResponse", {
          channelId: this.session.channelId,
          response: obj.value,
          pollId: this.polls[0].id,
        });
        localStorage.setItem('hmzep-'+this.polls[0].id, "1")
      }
    },
  },
  props: {
    session: {
      type: Object,
      required: true
    },
    polls: {
      type: Array,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: () => "430px",
    },
  },
};
</script>