<template>
  <section class="chat-app-window d-flex flex-column" ref="refChatContainer">
    <div class="active-chat">
      <b-collapse id="question-list-collapse" v-model="questionListVisible">
        <vue-perfect-scrollbar
          v-once
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area rounded-lg border-primary"
          :style="{height: chatHeight}"
        >
          <div class="chats">
            <div
              v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
              :key="msgGrp.senderId + String(index)"
              class="chat"
              :class="{ 'chat-left': true }"
            >
              <!-- <div class="chat-avatar">
                <b-avatar
                  size="36"
                  class="avatar-border-2 box-shadow-1"
                  variant="primary"
                  :text="msgGrp.senderName && msgGrp.senderName.substring(0, 2)"
                />
              </div> -->
              <div class="chat-body">
                <div class="small">
                  <span>{{msgGrp.senderName}}</span>
                </div>
                <div
                  v-for="msgData in msgGrp.messages"
                  :key="msgData.id"
                  class="chat-content ml-0"
                >
                  <div v-html="msgData.msg" />
                </div>
              </div>
            </div>
          </div>
        </vue-perfect-scrollbar>
      </b-collapse>
      <!-- Message Input -->
      <b-collapse id="question-form-collapse" v-model="questionFormVisible">
      <validation-observer ref="questionsForm" #default="{ invalid }">
        <b-form class="my-1" @submit.prevent="sendQuestion">
          <validation-provider
            #default="{ errors }"
            name="Question"
            vid="question"
            rules="required"
          >
            <div class="form-label-group">
              <b-form-textarea
                ref="refQuestion"
                id="question"
                rows="5"
                v-model="question"
                :state="errors.length > 0 ? false : null"
                name="question"
                placeholder="Type your question here"
              />
              <label for="question">Type your question here</label>
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="speaker"
            vid="speaker"
            rules=""
          >
            <div class="form-label-group mt-2">
              <b-form-input
                id="speaker"
                v-model="speaker"
                :state="errors.length > 0 ? false : null"
                name="speaker"
                placeholder="Address to speaker"
              />
              <label for="speaker">Address to speaker</label>
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <p>
            <em
              >Please Note: The session speaker/moderator will take up questions
              based on relevance and time available.</em
            >
          </p>
          <b-button type="submit" variant="primary" :disabled="invalid">
            Send Question
          </b-button>
          <b-button type="button" variant="outline-secondary" class="ml-1 float-right" @click="questionFormVisible = !questionFormVisible">
            Maybe Later
          </b-button>
        </b-form>
      </validation-observer>
    </b-collapse>
    <b-button
      block
      class="p-2 my-1 rounded-lg border-primary"
      style="font-size: 1.1rem"
      :class="questionFormVisible ? 'd-none' : 'collapsed'"
      :aria-expanded="questionFormVisible ? 'true' : 'false'"
      aria-controls="question-form-collapse"
      @click="questionFormVisible = !questionFormVisible"
      variant="primary"
    >
      Ask a Question
    </b-button>
    </div>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { ref, computed, nextTick } from "@vue/composition-api";
import {
  BAvatar,
  BForm,
  BInputGroup,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "Chat",
  components: {
    ValidationProvider, 
    ValidationObserver,
    BAvatar,
    BForm,
    BFormInput,
    BInputGroup,
    BButton,
    VuePerfectScrollbar,
    FeatherIcon,
  },
  props: {
    chatData: {
      type: Array,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: () => '430px'
    },
    profileUserAvatar: {
      type: String,
      required: false,
    },
    channelId: {
      type: String,
      required: true
    }
  },
  computed: {
    chatHeight() {
      // console.log('height', this.$refs)
      // console.log('parent height', this.$parent)
      // console.log('chatHeight:props', this.height)
      let _height = this.height || (this.$parent?.$el?.offsetHeight && this.$parent.$el.offsetHeight) + 'px'
      // console.log('_height', _height)
      return _height
    },
    questionListVisible: {
      get() {
        return !this.questionFormVisible
      },
      set(value) {
        return value
      }
    }
  },
  data() {
    return {
      required,
      questionFormVisible: false,
      question: "",
      speaker: "",
      chatInputMessage: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      }
    }
  },
  methods: {
   sendQuestion() {
      // console.log("this.session", this.session);
      this.$refs.questionsForm.validate().then((success) => {
        if (success) {
          // console.log(this.question, this.speaker);
          let message = this.question
          if (this.speaker && this.speaker.length>0) {
              message += '<br><br><em>For: ' + this.speaker + '</em>'
          }
          this.$store.dispatch('wsSendQuestion', {channelId: this.channelId, message: message })
          this.question = '';
          this.speaker = '';
          this.$toast({
            component: ToastificationContent,
            position: "bottom-left",
            props: {
              title: `Question Sent Successfully`,
              icon: "QuestionIcon",
              variant: "success",
              text: `Your question has been submitted. The session speaker/moderator may answer it based on relevance and time available.`,
            },
          });
          this.questionFormVisible = !this.questionFormVisible
        }
      });
    },

  },
  setup(props) {
    const refQuestion = ref("");
    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null);
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    };
    const focusQuestion = () => {
      const focusEl = refQuestion.value.$el || refQuestion.value
      // console.log('focusEl', focusEl)
      focusEl.focus()
    };

    const formattedChatData = computed(() => {
      const contact = {
        id: "",
        avatar: "",
      };
      let chatLog = [];
      // console.log("props.chatData", props.chatData);
      if (props.chatData) {
        chatLog = props.chatData.filter(message => message.type === 'question');
      }

      const formattedChatLog = [];
      let chatMessageSenderId = chatLog[0] ? chatLog[0].fromId : undefined;
      let msgGroup = {
        sender: chatMessageSenderId,
        senderName: chatMessageSenderId ? chatLog[0].fromName : "",
        senderTitle: chatMessageSenderId ? chatLog[0].senderTitle : "",
        messages: [],
      };

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.fromId) {
          msgGroup.messages.push({
            msg: msg.content,
            time: msg.time,
          });
        } else {
          chatMessageSenderId = msg.fromId;
          formattedChatLog.push(msgGroup);
          msgGroup = {
            senderId: msg.fromId,
            senderName: msg.fromName,
            senderTitle: msg.senderTitle,
            messages: [
              {
                msg: msg.content,
                time: msg.time,
              },
            ],
          };
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup);
      });
      // Scroll to bottom
      nextTick(() => { scrollToBottomInChatLog() })
      nextTick(() => { focusQuestion() })
      // console.log('formattedChatLog', props.chatData, formattedChatLog)
      return {
        formattedChatLog,
        contact,
      };
    });

    return {
      refQuestion,
      refChatLogPS,
      formattedChatData,
      scrollToBottomInChatLog,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
// .chat-app-form {
//   position: absolute;
//   bottom: 0;
//   margin-bottom: 10px;
//   width: 88%;
// }
</style>
