<template>
  <b-tabs pills class="border-primary rounded-lg h-100 shadow px-1 pt-1" id="refTabs">
    <b-tab title="Info" v-if="!currentSession || !currentSession.title">
      <div class="d-flex flex-column">
        <div class="mb-1 d-flex flex-row justify-content-between align-items-end">
          <span>{{this.$date(this.$store.state.event.event.scheduledStart).format('D MMM YYYY hh:mm a')}} - {{this.$date(this.$store.state.event.event.scheduledEnd).format('D MMM YYYY hh:mm a')}}</span>
          <div class="bg-light-secondary font-weight-bolder py-1 px-2 rounded-lg">Event Concluded</div>
        </div>
        <span class="h3 text-dark mb-1">{{this.$store.state.event.event.title}}</span>
        <div>
          <p>The event has concluded. You may watch the session recordings by clicking 'Watch Session' on the sessions list.</p>
          <b-button variant="outline-primary" href="/sessions">View Sessions</b-button>
        </div>
      </div>
    </b-tab>
    <b-tab title="Info" v-if="currentSession && currentSession.title">
      <div class="d-flex flex-column justify-content-between">
        <div>
          <div class="mb-1 d-flex flex-row justify-content-between align-items-end">
            <span>{{this.$date(currentSession.scheduledStart).format('DD MMM hh:mm a')}} - {{this.$date(currentSession.scheduledEnd).format('hh:mm a')}}</span>
            <div v-if="sessionStatus ==='live'" class="bg-light-secondary font-weight-bolder py-1 px-3 rounded-lg" style="color:red !important;"><font-awesome-icon :icon="['fal', 'dot-circle']" size='lg' /> LIVE</div>
            <div v-if="sessionStatus ==='ondemand'" class="bg-light-secondary font-weight-bolder py-1 px-2 rounded-lg"><font-awesome-icon :icon="['fal', 'play']" /> On Demand</div>
            <vue-countdown-timer
              v-if="currentSession.scheduledStart && sessionStatus==='waiting'"
              class="bg-light-secondary p-1 rounded-lg"
              @ended="setSessionLive"
              :start-time="this.$date().valueOf()"
              :end-time="this.$date(currentSession.scheduledStart).valueOf()"
              :interval="1000"
              label-position="begin"
              :day-txt="'days'"
              :hour-txt="'hours'"
              :minutes-txt="'min'"
              :seconds-txt="'sec'">
              <template slot="countdown" slot-scope="scope">
                <div class="text-primary d-flex flex-row justify-content-start">
                  <div v-if="scope.props.showDay" class="d-flex flex-column align-items-center pr-1">
                    <span style="font-size: 1.2rem; font-weight: bold">{{ scope.props.days }}</span> {{ scope.props.dayTxt }}
                  </div>
                  <div v-if="scope.props.showHour" class="d-flex flex-column align-items-center pr-1">
                    <span style="font-size: 1.2rem; font-weight: bold">{{ scope.props.hours }}</span> {{ scope.props.hourTxt }}
                  </div>
                  <div v-if="scope.props.showMinute" class="d-flex flex-column align-items-center pr-1">
                    <span style="font-size: 1.2rem; font-weight: bold">{{ scope.props.minutes }}</span> {{ scope.props.minutesTxt }}
                  </div>
                  <div class="d-flex flex-column align-items-center">
                    <span style="font-size: 1.2rem; font-weight: bold">{{ scope.props.seconds }}</span> {{ scope.props.secondsTxt }}
                  </div>
                </div>
              </template>
            </vue-countdown-timer>
          </div>

          <span v-if="currentSession.category" class="text-muted">{{currentSession.category}}</span>
          <span v-if="currentSession.title" class="h3 text-dark mb-1">{{currentSession.title}}</span>
        </div>
        <vue-perfect-scrollbar
          v-if="currentSession.description"
          :settings="perfectScrollbarSettings"
          class="mt-1 scrollable-container scroll-area"
          v-html="currentSession.description"
          style="max-height:250px"
        >
        </vue-perfect-scrollbar>
        <!-- <b-avatar-group
          class="my-2"
          size="48px"
          v-if="currentSessionSpeakers && currentSessionSpeakers.length>0"
        >
          <b-avatar
            v-for="speaker in currentSessionSpeakers"
            :key="speaker.id"
            v-b-tooltip.hover.bottom="speaker.name"
            :src="speaker.avatar"
            class="pull-up border-secondary"
            :button="true"
            @click="showParticipantDetail(speaker)"
          />
            <h6 class="align-self-center cursor-pointer ml-1 mb-0">
          </h6>
        </b-avatar-group> -->
        <!-- <b-list-group>
          <b-list-group-item v-for="speaker of currentSessionSpeakers" :key="speaker.id">{{speaker.name}}, {{speaker.description}}{{speaker.designation}} {{speaker.organization}}</b-list-group-item>
        </b-list-group> -->
        <div v-if="currentSessionPartners && currentSessionPartners.length > 0" class="my-2">
          <strong>Session Partners</strong>
          <b-row>
            <b-col sm="6" md="4" v-for="partner in currentSessionPartners" class="rounded-lg box-shadow-2 m-1 p-1 text-center" :key="partner.slug">
              <b-link :id="partner.title" :href="partner.link" target="_blank" class="text-center">
                <b-img :src="partner.logo" height="auto" width="100px" :alt="partner.title"/>
              </b-link>
              <b-popover :target="partner.title" triggers="hover" custom-class="rounded-lg box-shadow-4 text-center" placement="auto">
                  <b-img :src="partner.logo" style="max-width: 250px; height: auto; object-fit: contain;" /><br />
                  {{partner.title}}
                </b-popover>
            </b-col>
          </b-row>
        </div>
        <div v-if="currentSession.sponsorLogo" :src="currentSession.sponsorLogo" class="my-2">
          <strong>Session Partner</strong>
          <b-img height="100px" alt="" left/> <span v-if="currentSession.sponsor" class="small text-dark">{{currentSession.sponsor}}</span>
        </div>
      </div>
    </b-tab>
    <b-tab title="Chat" v-if="chatEnabled" ref="refChatTab">
        <chat :key="chatKey" :chatData="channelMessages" :channelId="currentSession.channelId" v-if="currentSession.channelId" :height="sidebarHeight"/>
        <!-- <b-card class="border-primary shadow" v-if="!messages || messages.length===0">
          <b-card-text class="pt-2 text-center" >
            <font-awesome-icon :icon="['fal', 'comment-alt-lines']" size="3x" /><br /><br />
            Chat is disabled by host.
          </b-card-text>
        </b-card> -->
        <!-- <chat /> -->
    </b-tab>
    <b-tab title="Q&amp;A" v-if="questionsEnabled">
      <questions :key="qnaKey" :chatData="channelMessages" :channelId="currentSession.channelId" v-if="currentSession.channelId"  :height="sidebarHeight" />
      <!-- <b-card class="border-primary shadow">
        <b-card-text class="pt-2 text-center" v-if="!questions || questions.length===0">
          <font-awesome-icon :icon="['fal', 'question']" size="3x" /><br /><br />
          Q&amp;A is disabled by host.
        </b-card-text>
        <questions :session="currentSession" v-if="currentSession.channelId" />
      </b-card> -->
    </b-tab>
    <b-tab title="Polls" v-if="pollEnabled && polls.length>0">
      <user-poll :polls="polls" :session="currentSession" v-if="polls && polls.length>0"  :height="sidebarHeight" />
      <!-- <b-card class="border-primary shadow">
        <b-card-text class="pt-2 text-center" v-if="!polls || polls.length===0">
          <font-awesome-icon :icon="['fal', 'poll-people']" size="3x" /><br /><br />
          No polls available at this time.
        </b-card-text>
      </b-card> -->
    </b-tab>
    <b-tab title="Sessions" v-if="sessionsEnabled && upcomingSessions && upcomingSessions.length>0">
      <agenda-card :data="session" v-for="session of upcomingSessions" :key="session.id" />
      
      <b-card class="border-primary shadow" v-if="!upcomingSessions || upcomingSessions.length===0">
        <b-card-text class="pt-2 text-center">
          <font-awesome-icon :icon="['fal', 'calendar-alt']" size="3x" /><br /><br />
          No more sessions available today.
        </b-card-text>
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCard, BCardText, BListGroup, BListGroupItem, } from "bootstrap-vue";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueCountdownTimer from '@/layouts/components/VueCountdownTimer.vue';
import Chat from '@/layouts/components/Chat.vue'
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import Questions from '@/layouts/components/Questions2.vue'
import AgendaCard from '@/views/pages/sessions/AgendaCardSmall.vue'
import UserPoll from '../../apps/poll/UserPoll.vue';

export default {
  name: "StageSidebar",
  components: {
    VueCountdownTimer,
    BTabs,
    BCard,
    BCardText,
    BTab,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    Chat,
    FeatherIcon,
    Questions,
    UserPoll,
    AgendaCard,
  },
  computed: {
    sidebarHeight() {
      let sidebarTabs = document.querySelector('#refTabs')
      // console.log(sidebarTabs);
      if (sidebarTabs) {
        // console.log('refTabs', window.getComputedStyle(sidebarTabs) )
      }
      // let height = this.$refs.refChatTab && this.$refs.refChatTab.$parent && this.$refs.refChatTab.$parent.$el.clientHeight || 430
      let height = this.$parent?.$children[0]?.$el?.offsetHeight && this.$parent.$children[0].$el.offsetHeight || this.$parent?.$children[0]?.$el?.clientHeight && this.$parent.$children[0].$el.clientHeight || 0
      if (height === 0) {
        height = 430
      } else {
        height -= 130
      }
      // if (height === 0) {
      //   if (window.innerWidth >= 1200) height = 695
      //   else if (window.innerWidth >= 992) currentBreakpoint.value = 'lg'
      //   else if (window.innerWidth >= 768) currentBreakpoint.value = 'md'
      //   else if (window.innerWidth >= 576) currentBreakpoint.value = 'sm'
      //   else currentBreakpoint.value = 'xs'
      // }
      // console.log('window.innerWidth', window.innerWidth, window.innerHeight)
      // console.log('StageSidebar:sidebarHeight', height, this.$parent.$children[0].$el.clientHeight, this.$parent.$children[0].$el.offsetHeight)
      return height + 'px'
    },
    sessions() {
      return this.$store.state.sessions.sessions
    },
    currentSessionSpeakers() {
      let _speakers = []
      if (this.currentSession && this.currentSession.speakerList) {
        _speakers = this.$store.getters["attendees/sessionSpeakers"](this.currentSession.speakerList, '');
      } 
      // console.log('stageSidebar currentSessionSpeakers', this.sessionStatus);
      return _speakers
    },
    currentSessionPartners() {
      let _partners = []
      if (this.currentSession && Array.isArray(this.currentSession.sponsors)) {
        _partners = this.$store.getters['sessions/sessionPartners'](this.currentSession.slug)
      }
      return _partners
    },
    upcomingSessions() {
      // console.log('stageSidebar upcomingSessions', this.$store.getters['sessions/upcomingSession']('stage'));
      const _session = this.$store.getters['sessions/upcomingSession']('stage')
      if (!_session) {
        return []
      }
      return [_session]
    },
    questions() {
      return []
    },
    polls() {
      let _polls = []
      // console.log('this.currentSession', this.currentSession);
      if (this.currentSession && this.currentSession.polls) {
        _polls = this.currentSession.polls
      }
      return _polls
    },
    messages() {
      return this.$store.state.messages.messages
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      chatKey: `chat-${this.$date().toISOString()}`,
      qnaKey: `qna-${this.$date().toISOString()}`,
      pollKey: `poll-${this.$date().toISOString()}`,
      chatEnabled: this.currentSession && this.currentSession.title && this.$store.getters['event/getSettings']('chatEnabled'),
      pollEnabled: this.currentSession && this.currentSession.title && this.$store.getters['event/getSettings']('pollEnabled'),
      questionsEnabled: this.currentSession && this.currentSession.title && this.$store.getters['event/getSettings']('questionsEnabled'),
      sessionsEnabled: this.currentSession && this.currentSession.title && this.$store.getters['event/getSettings']('sessionsEnabled'),
      channelMessages: this.currentSession && this.currentSession.title && this.$store.getters['messages/byChannel'](this.currentSession.channelId),
    };
  },
  watch: {
    messages() {
      this.chatKey = `chat-${this.$date().toISOString()}`
      this.qnaKey = `qna-${this.$date().toISOString()}`
      this.pollKey = `poll-${this.$date().toISOString()}`
      this.channelMessages = this.$store.getters['messages/byChannel'](this.currentSession.channelId)
      // console.log('watch messages', this.messages, this.currentSession.channelId)
    }
  },
  methods: {
    setSessionLive(sessionId) {
      this.$store.dispatch('sessions/updateCurrentSessions')
    },
  },
  props: {
    currentSession: {
      type: Object,
      default: () => {},
    },
    sessionStatus: {
      type: String,
      required: true,
    }
  },
};
</script>
<style lang="scss">
  .tab-content, .tab-pane {
    height: 95% !important;
    /* max-height: 70vh;*/
    overflow: auto;
  }
  .nav-pills, .nav-tabs {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: auto;
  }
</style>
 