<template>
    <div>
      <b-row class="match-height">
        <b-col md="12" lg="8">
          <VideoPlayer :options="videoOptions" :key="videoKey" :title="currentSession.title || event.title" :videoId="currentSession.slug || ''" />
        </b-col>
        <!-- Chat/Q&A/Poll Sidebar-->
        <b-col md="12" lg="4" class="mt-2 mt-lg-0">
            <stage-sidebar :currentSession="currentSession" :sessionStatus="sessionStatus"/>
        </b-col>
    </b-row>
    <b-row class="mt-2" align-h="between" v-if="speakers && speakers.length>0">
      <b-col><h2>Speakers</h2></b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="2" md="3" v-for="speaker in speakers" :key="speaker.id">
        <b-link variant="dark" class="text-dark h-100 w-100 d-flex flex-fill align-items-stretch" @click="showParticipantDetail(speaker)">
          <participant-card :data="speaker" :chat="false" :call="false" />
        </b-link>
      </b-col>
    </b-row>

    <b-row class="mt-2" align-h="between" v-if="partners && partners.length>0">
      <b-col><h2>Sponsors</h2></b-col>
    </b-row>
    <b-row class="match-height" v-if="partners && partners.length > 0">
      <b-col v-for="(partner, index) in partners" :key="index" xs="12" lg="3" md="4">
        <partner-card :data="partner" />
      </b-col>
    </b-row>
    <b-sidebar id='participant-details' v-model="showParticipantSidebar" width="30rem" right shadow backdrop no-header sidebar-class="border-left border-primary">
      <b-button variant="light-primary" style="position:absolute;top:2px;" v-b-toggle.participant-details><font-awesome-icon :icon="['fal', 'times-circle']" size="2x" class="text-primary" /></b-button>
      <ParticipantDetail :data="participantData" />
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BImg,
  BRow,
  BCol,
  BMedia,
  BSidebar,
} from "bootstrap-vue"
import { $themeConfig } from "@themeConfig"
import mime from 'mime-types';
import VueCountdownTimer from '@/layouts/components/VueCountdownTimer.vue';
import VideoPlayer from "@/layouts/components/video-player/NuevoVideoPlayer.vue"
import StageSidebar from "./StageSidebar.vue"
// import SpeakerCard from "@/layouts/components/SpeakerCard.vue"
import PartnerCard from '@/views/pages/partners/PartnerCard.vue'
import ParticipantCard from '@/views/pages/participants/ParticipantCard.vue'
import ParticipantDetail from '@/views/pages/participants/ParticipantDetail.vue'

export default {
  metaInfo() {
    return {
      title: this.currentSession.title || 'Stage',
    }
  },
  components: {
    VueCountdownTimer,
    BCard,
    BCardText,
    BLink,
    BImg,
    BRow,
    BCol,
    BMedia,
    BSidebar,
    VideoPlayer,
    StageSidebar,
    // SpeakerCard,
    PartnerCard,
    ParticipantCard,
    ParticipantDetail,
  },
  data() {
    return {
      participantData: {},
      showParticipantSidebar: false,
      sessionStatus: 'waiting',
      videoKey: 'waiting-videokey'
    };
  },
  computed: {
    event() {
      return this.$store.state.event.event
    },
    sessionId() {
      return (this.$route.params && this.$route.params.session ) || ''
    },
    videoOptions() {
      let _videoOptions = {
        autoplay: true,
        controls: true,
        preload: "auto",
        playsinline: true,
        poster: this.$store.state.event.event.meta.videoBanner || '',
        sources: []
      }
      if (this.currentSession && this.sessionStatus === 'ondemand') {
        // _videoOptions.sources.push({src: 'https://805016146538.us-east-1.playback.live-video.net/api/video/v1/us-east-1.111537435751.channel.Qwyv9mE0FNlE.m3u8', type: mime.lookup('https://805016146538.us-east-1.playback.live-video.net/api/video/v1/us-east-1.111537435751.channel.Qwyv9mE0FNlE.m3u8')})
        _videoOptions.sources.push({src: this.currentSession.recordingUrl, type: mime.lookup(this.currentSession.recordingUrl)})
      } else if (this.sessionStatus === 'live' && this.currentSession && this.currentSession.liveStream.playback) {
        _videoOptions.sources.push({src: this.currentSession.liveStream.playback, type: mime.lookup(this.currentSession.liveStream.playback)})
      } 
      // _videoOptions.sources = [{src: 'https://hummzspaces.s3.ap-south-1.amazonaws.com/sYFpRcTiHvU77CaitjsBPcAAhHrBVNug3RlMNPOM7qc4jlw9aaIugJfLRVQj/events/trqnVaMNPCLEqT4yr1pWQz/recordings/keynote/keynote-shabana-azmi.mp4', type: mime.lookup('https://hummzspaces.s3.ap-south-1.amazonaws.com/sYFpRcTiHvU77CaitjsBPcAAhHrBVNug3RlMNPOM7qc4jlw9aaIugJfLRVQj/events/trqnVaMNPCLEqT4yr1pWQz/recordings/keynote/keynote-shabana-azmi.mp4')}]
      // console.log('stage _videoOptions', _videoOptions);
      return _videoOptions
    },
    currentSession() {
      let _session = {}
      // console.log('this.sessionId', this.sessionId, this.$route.params);
      if (this.sessionId && this.sessionId !== '') {
        _session = this.$store.getters['sessions/byId'](this.sessionId)
        // console.log('_session', _session)
        this.sessionStatus = 'ondemand'
        this.videoKey = `ondemand-${_session.slug}`
      } else {
        // _session = this.$store.getters['sessions/currentSession']('stage')
        if (this.$store.state.sessions.stageStatus['stage']) {
          // check for live session
          if (this.$store.state.sessions.stageStatus['stage'].liveSession) {
            _session = this.$store.state.sessions.stageStatus['stage'].liveSession
            this.sessionStatus = 'live'
            this.videoKey = `live-${_session.slug}`
          } else if (this.$store.state.sessions.stageStatus['stage'].upcomingSession) {
            _session = this.$store.state.sessions.stageStatus['stage'].upcomingSession
            this.sessionStatus = 'waiting'
            this.videoKey = `waiting-${_session.slug}`
          }
        } 
      }
      if (!_session.title) {
        // check if this is a single sessions event and load the single session for viewing
        if (this.$store.state.sessions.sessions.length === 1) {
          _session = this.$store.state.sessions.sessions[0]
          this.sessionStatus = 'ondemand'
          this.videoKey = `ondemand-${_session.slug}`
        }
      }
      if (_session.channelId && _session.channelId.length>0) {
        this.$store.dispatch('joinChannel', _session.channelId)
      }
      // console.log('_session', _session)
      return _session
    },
    upcomingSession() {
      // console.log('stage upcomingSession');
      return this.$store.state.sessions.stageStatus['stage'].upcomingSession || {}
      // return this.$store.getters['sessions/upcomingSession']('stage')
    },
    liveSession() {
      // console.log('stage liveSession');
      // console.log('stageStatus', this.$store.state.sessions.stageStatus)
      return this.$store.state.sessions.stageStatus['stage'].liveSession || {}
      // return this.$store.getters['sessions/liveSessions']('stage')
    },
    sessions() {
      return this.$store.state.sessions.sessions
    },
    speakers() {
      let _speakers = []
      if (this.currentSession && this.currentSession.speakerList) {
        _speakers = this.$store.getters["attendees/sessionSpeakers"](this.currentSession.speakerList, '');
      } 
      return _speakers
    },
    partners() {
      return this.$store.getters["partners/byPage"]('stage');
    },
  },
  methods: {
    showParticipantDetail(participant) {
      this.participantData = participant
      this.showParticipantSidebar = true
    } 
  },
  mounted() {
    this.$store.dispatch('sessions/updateCurrentSessions')
  },
  setup() {
    // App Name
    const { appLogoImage } = $themeConfig.app;
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
    return {
      appLogoImage,
      perfectScrollbarSettings,
    };
  },
};
</script>

<style>
.title-card .card-body {
   padding: 0.7rem 1.5rem;
}
</style>
